import React from "react";

import "../styles/centered-box.less";

import { Button, Layout, Row, Col } from "antd";

import HeaderPatinette from "../components/header";
import FooterPatinette from "../components/footer";

const { Content } = Layout;

//const sexeForm = Form.create({ name: "homeform" })(TemplateHomeForm)

export default function Cg() {
  return (
    <Layout className="centered-box">
      <HeaderPatinette />
      <Content className="content">
        <Row type="flex" justify="center">
          <Col lg={16} xs={24} className="main">
            <h2>Conditions générales</h2>
            <Row>
              <Col xs={24} style={{ marginTop: "30px" }}>
                <Button
                  type="primary"
                  target="_blank"
                  href="https://drive.google.com/open?id=118vqKCod2-kYsByOSKqy1cz8_AK6no57"
                  size="large"
                >
                  Télécharger les conditions générales &laquo;PROTECTION
                  RIDER&raquo;
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
      <FooterPatinette />
    </Layout>
  );
}
